import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ButtonTemplate from "../components/buttons/ButtonTemplate"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="mx-auto text-center my-16 lg: md:mt-32 ">
      <h1 className="text-4xl font-heading">404: NOT FOUND</h1>
      <p>Sorry, this page doesn&#39;t exist.</p>

      <ButtonTemplate
        to="/"
        text="Back to Home Page"
        className="text-white uppercase tracking-wide mt-8"
        index={1}
      />
    </div>
  </Layout>
)

export default NotFoundPage
